import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
} from "class-validator";
import { validationRegExpr } from "@shared/constants/validations-regexp";

@ValidatorConstraint({ name: "passwordStrength", async: false })
export class PasswordStrength implements ValidatorConstraintInterface {
  validate(text: string, args: ValidationArguments) {
    return (
      !!text &&
      text.length >= 8 &&
      validationRegExpr.oneUpperCase.test(text) &&
      validationRegExpr.specialAndDigitChar.test(text)
    );
  }

  defaultMessage(args: ValidationArguments) {
    return `Your password needs to meet the requirements`;
  }
}
