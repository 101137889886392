import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { IsPhoneNumber, IsString, MinLength } from "class-validator";

export interface ILoginForm {
  phoneNumber: string;
  password: string;
}

export class LoginFormFields implements ILoginForm {
  @MinLength(1, { message: `This field is mandatory` })
  @IsPhoneNumber()
  phoneNumber: string;

  @IsString()
  @MinLength(1, { message: `This field is mandatory` })
  password: string;
}

export const LoginFieldsResolver = classValidatorResolver(LoginFormFields);
