import { Validate } from "class-validator";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { PasswordStrength } from "../../validators/password-strength";
import { SamePassword } from "../../validators/same-password";

export interface IChangePasswordForm {
  password: string;
  passwordRepeat: string;
}

export class ChangePasswordFormFields implements IChangePasswordForm {
  @Validate(PasswordStrength)
  password: string;

  @Validate(SamePassword)
  passwordRepeat: string;
}

export const ChangePasswordFieldsResolver = classValidatorResolver(
  ChangePasswordFormFields,
);
