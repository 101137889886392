import { Button, Layout, Result } from "antd";
import { useNavigate } from "react-router-dom";

import styles from "./AccessDenied.module.scss";
import { ROUTES } from "@routes/routes";

const AccessDenied = () => {
  const navigate = useNavigate();

  return (
    <Layout className={styles.layout}>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button type="primary" onClick={() => navigate(ROUTES.MAIN_PAGE)}>
            Back Home
          </Button>
        }
      />
    </Layout>
  );
};

export default AccessDenied;
