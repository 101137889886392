import { DiEntity, DiEntityIdentifier } from "@core/di/types";
import { DI_TOKENS } from "@shared/constants/di";
import { AuthService } from "@shared/services/auth.service";
import { PlayerCardService } from "@shared/services/player-card.service";

export const sharedAppServices: Array<{
  diToken: DiEntityIdentifier;
  entity: DiEntity;
}> = [
  { diToken: DI_TOKENS.authService, entity: AuthService },
  { diToken: DI_TOKENS.playerCardService, entity: PlayerCardService },
];
