import { appInject } from "@core/di/utils";
import { DI_TOKENS } from "@shared/constants/di";
import {
  AuthStatusData,
  IAuthService,
} from "@shared/interfaces/auth-service.interface";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { appMakeObservable, appObservable } from "@core/state-management/utils";
import { LoginScreen } from "./login-screen";
import { ILoginForm } from "./forms/login-form/login-form.validator";
import { AuthStatus } from "@shared/constants/auth";

export class LoginViewModel {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private _activeScreen: LoginScreen = LoginScreen.LOGIN_FORM;

  constructor() {
    appMakeObservable(this, {
      _activeScreen: appObservable,
    });
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  get activeScreen() {
    return this._activeScreen;
  }

  private handleUpdateAuthStatus(authStatusData: AuthStatusData) {
    switch (authStatusData.status) {
      case AuthStatus.passwordChangeRequired:
        this._activeScreen = LoginScreen.CHANGE_PASSWORD_FORM;
        break;
      default:
        break;
    }
  }

  async login({ phoneNumber, password }: ILoginForm): Promise<void> {
    const authStatusData = await this.authService.login(phoneNumber, password);
    this.handleUpdateAuthStatus(authStatusData);
  }

  get mfaDelayMinutes() {
    return this.configService.mfaDelayMinutes;
  }

  async savePassword(password: string) {
    try {
      const authStatusData = await this.authService.handleNewPassword(password);
      this.handleUpdateAuthStatus(authStatusData);
    } catch (ex: any) {
      throw ex.name === "NotAuthorizedException"
        ? new Error("Invalid credentials")
        : new Error("Something went wrong");
    }
  }
}
