import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  Alert,
} from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Tiers } from "@shared/resources/tiers";
import { Positions } from "@shared/resources/positions";
import { PlayerCardDto } from "@shared/types/player-card/player-card.dto";

const requiredFieldRule = {
  required: true,
  whitespace: true,
  message: "This field is required",
};

interface IModal {
  open: boolean;
  player: PlayerCardDto | null;
  onClose: () => void;
  onSave: (data: PlayerCardDto) => void;
  isLoading: boolean;
  isError?: boolean;
}

const ChangePlayerModal = ({
  open,
  player,
  onClose,
  onSave,
  isLoading,
  isError,
}: IModal) => {
  const isEditingMode = player?.id;

  const [form] = Form.useForm();

  useEffect(() => {
    if (player) {
      const formattedPlayer = {
        ...player,
        draftYear: dayjs(player.draftYear.toString(), "YYYY"),
        playerID: String(player.playerID),
      };

      form.setFieldsValue(formattedPlayer);
    }
  }, [form, player]);

  const handleFinish = (values: PlayerCardDto) => {
    const formattedValues = {
      ...values,
      draftYear: new Date(values.draftYear).getFullYear(),
      playerID: Number(values.playerID),
    };
    onSave(formattedValues);
  };

  return (
    <Modal
      title={isEditingMode ? "Change Player" : "Add Player"}
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <Form
        key={JSON.stringify(player)}
        form={form}
        requiredMark={false}
        onFinish={handleFinish}
      >
        {isError && (
          <Form.Item>
            <Alert
              message="Error"
              description="An error occurred while saving the player data. Please try again."
              type="error"
              showIcon
            />
          </Form.Item>
        )}

        <Form.Item
          name="playerName"
          label="Name"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[
            requiredFieldRule,
            { type: "string", message: "Name must be a string" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="team"
          label="Team"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[
            requiredFieldRule,
            { type: "string", message: "Team must be a string" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="division"
          label="Division"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[
            requiredFieldRule,
            { type: "string", message: "Division must be a string" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="position"
          label="Position"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[requiredFieldRule]}
        >
          <Select options={Positions} />
        </Form.Item>
        <Form.Item
          name="tier"
          label="Tier"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[requiredFieldRule]}
        >
          <Select options={Tiers} />
        </Form.Item>
        <Form.Item
          name="playerID"
          label="Player ID"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[
            requiredFieldRule,
            {
              validator: (_, value) => {
                const numValue = Number(value);
                if (isNaN(numValue) || numValue < 0 || numValue > 9999999) {
                  return Promise.reject(
                    new Error("Player ID must be between 0 and 9999999"),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="playerImageUrl"
          label="Player Image Url"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[
            requiredFieldRule,
            { type: "url", message: "Must be a valid URL" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="teamImageUrl"
          label="Team Image Url"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[
            requiredFieldRule,
            { type: "url", message: "Must be a valid URL" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="draftYear"
          label="Draft Year"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[
            {
              validator: (_, value) => {
                const year = value ? value.year() : null;
                if (year && (year < 1970 || year > new Date().getFullYear())) {
                  return Promise.reject(
                    new Error(
                      `Year must be between 1970 and ${new Date().getFullYear()}`,
                    ),
                  );
                }
                return Promise.resolve();
              },
              message: `Year must be between 1970 and ${new Date().getFullYear()}`,
            },
          ]}
        >
          <DatePicker picker="year" />
        </Form.Item>
        <Form.Item
          name="isDisabled"
          label="Disabled"
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          valuePropName="checked"
          rules={[{ type: "boolean", message: "Value must be a boolean" }]}
        >
          <Switch />
        </Form.Item>
        <Space style={{ justifyContent: "flex-end", width: "100%" }}>
          <Form.Item>
            <Button type="default" onClick={onClose}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {isEditingMode ? "Save" : "Add"}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default ChangePlayerModal;
