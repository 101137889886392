import React, { FC, useEffect, KeyboardEvent } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  ILoginForm,
  LoginFieldsResolver,
  LoginFormFields,
} from "./login-form.validator";
import { nameOf } from "@shared/utils/nameof";
import styles from "../../Login.module.scss";
import { Button, Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

export interface LoginFormProps {
  onSubmit: (formData: ILoginForm) => void;
  isLoading: boolean;
  isInvalidCredentials: boolean;
}

const LoginForm: FC<LoginFormProps> = ({
  onSubmit,
  isLoading,
  isInvalidCredentials,
}: LoginFormProps) => {
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<LoginFormFields>({ resolver: LoginFieldsResolver });

  useEffect(() => {
    if (isInvalidCredentials) {
      setError(nameOf<ILoginForm>("phoneNumber"), {
        message: `Invalid phone number or password`,
      });
      setError(nameOf<ILoginForm>("password"), { message: " " });
    } else {
      clearErrors();
    }
  }, [isInvalidCredentials, setError, clearErrors]);

  const submitForm = (formData: ILoginForm) => {
    onSubmit(formData);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === "Enter") {
      handleSubmit(submitForm)();
    }
  };

  return (
    <Form
      name="login"
      labelCol={{ span: 6 }}
      className={styles.form}
      onFinish={handleSubmit(submitForm)}
      onKeyDown={(e) => onKeyPress(e)}
      autoComplete="off"
    >
      <Form.Item
        label="Phone"
        validateStatus={errors.phoneNumber ? "error" : ""}
        help={errors.phoneNumber?.message}
      >
        <Controller
          name={nameOf<ILoginForm>("phoneNumber")}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              {...field}
              prefix={<UserOutlined />}
              placeholder="Phone number"
            />
          )}
        />
      </Form.Item>
      <Form.Item
        label="Password"
        validateStatus={errors.password ? "error" : ""}
        help={errors.password?.message}
      >
        <Controller
          name={nameOf<ILoginForm>("password")}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input.Password
              {...field}
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          )}
        />
      </Form.Item>
      <Form.Item className={styles.buttonContainer}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
