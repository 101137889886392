import { Outlet } from "react-router-dom";
import { Layout } from "antd";

import styles from "./MainLayout.module.scss";
import Header from "@shared/components/Header/Header";
import Sidebar from "@shared/components/Sidebar/Sidebar";

const MainLayout = () => {
  return (
    <Layout className={styles.layout}>
      <Header />
      <Layout>
        <Sidebar />
        <Layout.Content>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
