import { useEffect, useRef, useState } from "react";
import {
    Button,
    Col,
    Form,
    Layout,
    Row,
    Table,
    TablePaginationConfig,
    Tag,
    Input,
    Typography,
    Select,
} from "antd";
import { CheckCircleOutlined, StopOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import styles from "./Players.module.scss";
import ChangePlayerModal from "./ChangePlayerModal/ChangePlayerModal";
import { appInject } from "@core/di/utils";
import { IPlayerCardService } from "@shared/interfaces/player-card.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { useMutation, UseMutationResult, useQuery } from "react-query";
import { PlayerCardDto } from "@shared/types/player-card/player-card.dto";
import { Tiers } from "@shared/resources/tiers";

type TierType = "ICON" | "PLATINUM" | "GOLD" | "SILVER" | "BRONZE";

const ALL = 'All';

const getTierByType = (type: TierType) => {
    switch (type.toUpperCase()) {
        case "ICON": {
            return <Tag color="#8b0000">{type}</Tag>;
        }

        case "PLATINUM": {
            return <Tag color="#9400d3">{type}</Tag>;
        }

        case "GOLD": {
            return <Tag color="#aa6c39">{type}</Tag>;
        }

        case "SILVER": {
            return <Tag color="#c0c0c0">{type}</Tag>;
        }

        case "BRONZE": {
            return <Tag color="#5c3a0a">{type}</Tag>;
        }

        default:
            return null;
    }
};

const getStatusByType = (status: boolean) =>
    status ? (
        <StopOutlined style={{ color: "red", fontSize: 18 }} />
    ) : (
        <CheckCircleOutlined style={{ color: "green", fontSize: 18 }} />
    );

const defaultPlayer = {
    playerName: "",
    team: "",
    division: "",
    position: "",
    tier: "",
    draftYear: dayjs().year(),
    isDisabled: false,
    playerImageUrl: "https://",
    teamImageUrl: "https://",
};

const columns = [
    {
        key: "id",
        dataIndex: "id",
        title: "ID",
    },
    {
        key: "playerName",
        dataIndex: "playerName",
        title: "Name",
    },
    {
        key: "team",
        dataIndex: "team",
        title: "Team",
    },
    {
        key: "division",
        dataIndex: "division",
        title: "Division",
    },
    {
        key: "position",
        dataIndex: "position",
        title: "Position",
    },
    {
        key: "tier",
        dataIndex: "tier",
        title: "Tier",
        render: getTierByType,
    },
    {
        key: "draftYear",
        dataIndex: "draftYear",
        title: "Draft Year",
    },
    {
        key: "isDisabled",
        dataIndex: "isDisabled",
        title: "Disabled",
        render: getStatusByType,
    },
];

const ALL_OBJECT = { label: "All", value: ALL };

const TIERS = [
    { ...ALL_OBJECT, label: "Filter by tier" },
    ...Tiers
]

const DISABLED_FILTER = [
    { ...ALL_OBJECT, label: "Filter by status" },
    { label: "Enabled", value: 0 },
    { label: "Disabled", value: 1 },
]

const Players = () => {

    const [searchText, setSearchText] = useState('');
    const [tier, setTier] = useState(ALL);
    const [disabled, setDisabled] = useState(ALL);
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

    const [page, setPage] = useState(1);
    const [currentPageSize, setPageSize] = useState(20);

    const [containerHeight, setContainerHeight] = useState(0);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [selectedPlayer, setSelectedPlayer] = useState<PlayerCardDto | null>(
        null,
    );

    const playerCardsService = appInject<IPlayerCardService>(
        DI_TOKENS.playerCardService,
    );

    const downloadPlayerCardsQuery = useQuery(
        ["download-player-cards-list", page, currentPageSize, debouncedSearchText, tier, disabled],
        () =>
            playerCardsService.getPlayerCardsList({
                page,
                pageSize: currentPageSize,
                query: debouncedSearchText,
                tier: tier === ALL ? undefined : tier ?? undefined,
                disabled: disabled === ALL ? undefined : disabled ?? undefined,
            }),
        {
            refetchOnMount: true,
        },
    );

    const createPlayerMutation: UseMutationResult<
        PlayerCardDto,
        unknown,
        PlayerCardDto,
        unknown
    > = useMutation(
        (data: PlayerCardDto): Promise<PlayerCardDto> =>
            playerCardsService.create(data),
        {
            onSuccess: () => {
                setSelectedPlayer(null);
                setIsOpenModal(false);
                downloadPlayerCardsQuery.refetch();
            },
        },
    );

    const updatePlayerMutation: UseMutationResult<
        PlayerCardDto,
        unknown,
        PlayerCardDto,
        unknown
    > = useMutation(
        (data: PlayerCardDto): Promise<PlayerCardDto> =>
            playerCardsService.update(data),
        {
            onSuccess: () => {
                setSelectedPlayer(null);
                setIsOpenModal(false);
                downloadPlayerCardsQuery.refetch();
            },
        },
    );

    const handleCloseDialog = () => {
        setIsOpenModal(false);
        setSelectedPlayer(null);
    };

    const handleSaveDialog = (data: PlayerCardDto) => {
        if (selectedPlayer?.id) {
            updatePlayerMutation.mutate({ ...data, id: selectedPlayer.id });
        } else {
            if (selectedPlayer) {
                createPlayerMutation.mutate(data);
            }
        }
    };

    const containerRef = useRef<any>();

    const onOpenPlayerCard = (player: PlayerCardDto) => {
        setSelectedPlayer(player);
        setIsOpenModal(true);
    };

    const onTableChange = ({ current, pageSize }: TablePaginationConfig) => {
        setPage(current || 1);
        if (pageSize !== currentPageSize) {
            setPageSize(pageSize || 20);
            setPage(1);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if ("offsetHeight" in containerRef.current) {
                setContainerHeight(containerRef.current.offsetHeight - 140);
            }
        }, 100);
    }, []);

    // Update debounced search text after a delay
    useEffect(() => {
        const handler = setTimeout(() => {
            setPage(1);
            setDebouncedSearchText(searchText);
        }, 300); // Adjust debounce delay as needed

        // Clear the timeout if the effect re-runs (when searchText changes)
        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    const handleSearch = (e: any) => {
        setSearchText(e.target.value);
    };

    const resetFilters = () => {
        setSearchText('');
        setDisabled(ALL);
        setTier(ALL);
    }

    return (
        <Layout className={styles.tableLayout}>
            {isOpenModal ? (
                <ChangePlayerModal
                    open={isOpenModal}
                    player={selectedPlayer}
                    onClose={handleCloseDialog}
                    onSave={handleSaveDialog}
                    isLoading={
                        createPlayerMutation.isLoading || updatePlayerMutation.isLoading
                    }
                    isError={createPlayerMutation.isError || updatePlayerMutation.isError}
                />
            ) : (
                <></>
            )}

            <Row style={{ height: 32, justifyContent: "space-between",alignItems: "flex-start" }}>
                <Col
                    span={7}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        rowGap: 5,
                        columnGap: 5,
                    }}>
                    <Typography.Title style={{ margin: 0 }} level={5}>
                        Players ({downloadPlayerCardsQuery.data?.total})
                    </Typography.Title>

                    <Button
                        type="primary"
                        onClick={() => {
                            setSelectedPlayer(defaultPlayer);
                            setIsOpenModal(true);
                        }}
                    >
                        Add Player
                    </Button>
                </Col>
                <Col>
                    <Form.Item
                        className="search-input-container">

                        <Input
                            onChange={handleSearch}
                            value={searchText}
                            name="search"
                            style={{
                                minWidth: 200,
                            }}
                            placeholder="Search by Player, Team, Division, Position etc" />

                        <Select
                            style={{
                                minWidth: 160,
                            }}
                            options={DISABLED_FILTER}
                            value={disabled}
                            onChange={setDisabled} />

                        <Select
                            style={{
                                minWidth: 130,
                            }}
                            options={TIERS}
                            value={tier}
                            onChange={setTier} />

                        <Button
                            type="primary"
                            onClick={resetFilters}
                        >
                            Reset
                        </Button>

                    </Form.Item>
                </Col>
            </Row>
            <Layout.Content
                ref={containerRef}
                className={styles.content}
                style={{ backgroundColor: "white" }}
            >
                <Table
                    rowKey="key"
                    dataSource={downloadPlayerCardsQuery.data?.data || []}
                    onRow={(player: PlayerCardDto) => ({
                        onClick: () => onOpenPlayerCard(player),
                    })}
                    onChange={onTableChange}
                    //rowClassName={styles.tableRow}
                    columns={columns}
                    loading={downloadPlayerCardsQuery.isFetching}
                    pagination={{
                        current: page,
                        total: downloadPlayerCardsQuery.data?.total || 0,
                        pageSize: currentPageSize,
                        showSizeChanger: true,
                    }}
                    size="small"
                    scroll={{ y: containerHeight }}
                />
            </Layout.Content>
        </Layout>
    );
};

export default Players;
