import { Avatar, Dropdown, Layout, Space } from "antd";
import { DownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";

import styles from "./Header.module.scss";
import Logo from "@shared/components/Logo/Logo";
import { appInject } from "@core/di/utils";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@routes/routes";

const Header = () => {
  const navigate = useNavigate();
  const authService = appInject<IAuthService>(DI_TOKENS.authService);
  const items = [
    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const onClick = ({ key }: { key: string }) => {
    if (key === "logout") {
      authService.signOut();
      navigate(ROUTES.LOGIN_PAGE);
    }
  };

  return (
    <Layout.Header className={styles.header}>
      <div className={styles.item}>
        <Logo />
      </div>
      <Dropdown menu={{ items, onClick }} trigger={["click"]}>
        <Space>
          <Avatar size="small" icon={<UserOutlined />} />
          Admin
          <DownOutlined />
        </Space>
      </Dropdown>
    </Layout.Header>
  );
};

export default Header;
