import { ReactNode } from "react";
import { ConfigProvider, App } from "antd";

const AntdProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ConfigProvider>
      <App>{children}</App>
    </ConfigProvider>
  );
};

export default AntdProvider;
