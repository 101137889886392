import { Button, Layout, Result } from "antd";
import { useNavigate } from "react-router-dom";

import styles from "./InternalServer.module.scss";
import { ROUTES } from "@routes/routes";

const InternalServer = () => {
  const navigate = useNavigate();

  return (
    <Layout className={styles.layout}>
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Button type="primary" onClick={() => navigate(ROUTES.MAIN_PAGE)}>
            Back Home
          </Button>
        }
      />
    </Layout>
  );
};

export default InternalServer;
