import { BrowserRouter } from "react-router-dom";

import AppRouter from "./routes";
import AntdProvider from "@shared/providers/AntdProvider";

function App() {
  return (
    <AntdProvider>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </AntdProvider>
  );
}

export default App;
