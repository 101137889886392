import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "reflect-metadata";
import { QueryClient, QueryClientProvider } from "react-query";
import { initializeStateManagement } from "@core/state-management/setup";
import { appInject } from "@core/di/utils";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  },
});

async function initializeApp() {
  const container = document.getElementById("root") as HTMLElement;
  const root = ReactDOM.createRoot(container);

  initializeStateManagement();
  const config = appInject<IConfigService>(DI_TOKENS.configService);
  try {
    await config.initialize();
    const httpClientService = appInject<IHttpClientService>(
      DI_TOKENS.httpClientService,
    );
    const authService = appInject<IAuthService>(DI_TOKENS.authService);
    httpClientService.setConfig({
      refreshToken: authService.refreshToken,
      getAccessToken: () => authService.tokens.access,
      getUserLoginStatus: () => authService.isLoggedIn,
      getTokenRefreshStatus: () => authService.tokenRefreshStatus,
    });
    await authService.checkAuthorization();
    root.render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </React.StrictMode>,
    );
  } catch (err) {
    root.render(<div>Error Page</div>);
    throw err;
  }
}

initializeApp();

reportWebVitals();
