import React, { FC, useMemo, useState } from "react";
import { appObserver } from "@core/state-management/utils";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";
import { LoginViewModel } from "./login.vm";
import { useMutation, UseMutationResult } from "react-query";
import { ILoginForm } from "./forms/login-form/login-form.validator";
import { LoginScreen } from "./login-screen";
import { LoginForm } from "./forms/login-form";
import Logo from "@shared/components/Logo/Logo";
import styles from "./Login.module.scss";
import { IChangePasswordForm } from "./forms/change-password-form/change-password-form.validator";
import { ChangePasswordForm } from "./forms/change-password-form";
import { ROUTES } from "@routes/routes";

export type LoginProps = object;

const Login: FC<LoginProps> = appObserver(() => {
  const navigate = useNavigate();

  const $vm = useMemo(() => new LoginViewModel(), []);
  const [isInvalidCredentials, setIsInvalidCredentials] = useState(false);

  const loginMutation: UseMutationResult<void, unknown, ILoginForm, unknown> =
    useMutation((data: ILoginForm): Promise<void> => $vm.login(data), {
      onSuccess: () => {
        if ($vm.isLoggedIn) {
          navigate(ROUTES.MAIN_PAGE);
        }
      },
      onError: () => {
        setIsInvalidCredentials(true);
      },
    });

  const changePasswordMutation: UseMutationResult<
    void,
    unknown,
    IChangePasswordForm,
    unknown
  > = useMutation(
    (data: IChangePasswordForm): Promise<void> =>
      $vm.savePassword(data.password),
    {
      onSuccess: () => {
        if ($vm.isLoggedIn) {
          navigate(ROUTES.MAIN_PAGE);
        }
      },
      onError: (err) => {
        console.log("LOGIN ERROR: ", err);
        setIsInvalidCredentials(true);
      },
    },
  );

  let form = <></>;
  if ($vm.activeScreen === LoginScreen.LOGIN_FORM) {
    form = (
      <LoginForm
        onSubmit={(formData) => {
          loginMutation.mutate(formData);
        }}
        isLoading={loginMutation.isLoading}
        isInvalidCredentials={isInvalidCredentials}
      />
    );
  } else if ($vm.activeScreen === LoginScreen.CHANGE_PASSWORD_FORM) {
    form = (
      <ChangePasswordForm
        onSubmit={(formData) => {
          changePasswordMutation.mutate(formData);
        }}
        isLoading={loginMutation.isLoading}
      />
    );
  }
  return (
    <Layout.Content className={styles.content}>
      <Logo />
      {form}
    </Layout.Content>
  );
});

export default Login;
