import { Result, Button, Layout } from "antd";
import { useNavigate } from "react-router-dom";

import styles from "./NotFound.module.scss";
import { ROUTES } from "@routes/routes";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Layout className={styles.layout}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => navigate(ROUTES.MAIN_PAGE)}>
            Back Home
          </Button>
        }
      />
    </Layout>
  );
};

export default NotFound;
