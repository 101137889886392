import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { appInject } from "@core/di/utils";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { ROUTES } from "@routes/routes";

export type AuthorizedUserGuardProps = object;

const UnauthorizedUserGuard: FC<AuthorizedUserGuardProps> = () => {
  const authService = appInject<IAuthService>(DI_TOKENS.authService);

  if (authService.isLoggedIn) {
    return <Navigate to={ROUTES.MAIN_PAGE} />;
  }

  return <Outlet />;
};

export default UnauthorizedUserGuard;
