import { useEffect } from 'react';
import { Button, DatePicker, Form, Input, Modal, Space, Switch } from 'antd';

const requiredFieldRule = {
  required: true,
  whitespace: true,
  message: '',
};

interface INotification {
  id: string;
  type: string;
  title: string;
  body: string;
  date: Date;
  isRepeating: boolean;
}

interface IModal {
  open: boolean;
  notification: INotification | null;
  onClose: () => void;
}

const ChangeNotificationModal = ({ open, notification, onClose }: IModal) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (notification) {
      form.setFieldsValue(notification);
    }
  }, [form, notification]);

  return (
    <Modal title='Change Notification' open={open} onCancel={onClose} footer={null}>
      <Form form={form} requiredMark={false} onFinish={() => console.log('hello')}>
        <Form.Item
          name='type'
          label='Type'
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[requiredFieldRule]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='title'
          label='Title'
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[requiredFieldRule]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='body'
          label='Body'
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[requiredFieldRule]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='date'
          label='Date of Delivery'
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          rules={[requiredFieldRule]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name='isRepeating'
          label='Repeating'
          labelCol={{ span: 24, style: { padding: 0 } }}
          style={{ margin: 0 }}
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
        <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
          <Form.Item>
            <Button type='default' onClick={onClose}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Save
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default ChangeNotificationModal;
