import React, { FC, KeyboardEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { nameOf } from "@shared/utils/nameof";
import { Button, Form, Input } from "antd";
import { LockOutlined } from "@ant-design/icons";
import {
  ChangePasswordFieldsResolver,
  ChangePasswordFormFields,
  IChangePasswordForm,
} from "./change-password-form.validator";
import styles from "../../Login.module.scss";

export interface ChangePasswordFormProps {
  onSubmit: (formData: IChangePasswordForm) => void;
  isLoading: boolean;
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  onSubmit,
  isLoading,
}: ChangePasswordFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordFormFields>({
    resolver: ChangePasswordFieldsResolver,
  });

  const submitForm = (formData: IChangePasswordForm) => {
    onSubmit(formData);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLFormElement>): void => {
    if (e.key === "Enter") {
      handleSubmit(submitForm)();
    }
  };

  return (
    <Form
      name="changePassword"
      labelCol={{ span: 6 }}
      className={styles.form}
      onFinish={handleSubmit(submitForm)}
      onKeyDown={(e) => onKeyPress(e)}
      autoComplete="off"
    >
      <Form.Item
        label="Password"
        validateStatus={errors.password ? "error" : ""}
        help={errors.password?.message}
      >
        <Controller
          name={nameOf<IChangePasswordForm>("password")}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input.Password
              {...field}
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          )}
        />
      </Form.Item>
      <Form.Item
        label="Repeat"
        validateStatus={errors.passwordRepeat ? "error" : ""}
        help={errors.passwordRepeat?.message}
      >
        <Controller
          name={nameOf<IChangePasswordForm>("passwordRepeat")}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input.Password
              {...field}
              prefix={<LockOutlined />}
              placeholder="Repeat Password"
            />
          )}
        />
      </Form.Item>
      <Form.Item className={styles.buttonContainer}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePasswordForm;
