import { Outlet } from "react-router-dom";
import { Layout, theme } from "antd";

import styles from "./AuthorizationLayout.module.scss";

const { Content } = Layout;

const AuthorizationLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout className={styles.layout}>
      <Content
        className={styles.content}
        style={{ backgroundColor: colorBgContainer }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
};

export default AuthorizationLayout;
