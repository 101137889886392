import React, { useContext, useEffect, useRef, useState } from "react";
import type { FormProps, GetRef } from "antd";
import {
  Col,
  Layout,
  Row,
  Typography,
  Form,
  Table,
  InputNumber,
  Tag,
  Space,
  Button,
  DatePicker,
} from "antd";

import styles from "./Configurations.module.scss";
import { Dayjs } from "dayjs";

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: React.Key;
  round?: number;
  icon: number;
  platinum: number;
  gold: number;
  silver: number;
  bronze: number;
}

interface EditableRowProps {
  index: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm<FormProps>();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form as any}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>();
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex as any}
        rules={[{ required: true, message: "" }]}
      >
        <InputNumber
          ref={inputRef}
          min={0}
          max={100}
          onPressEnter={save}
          onBlur={save}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingInlineEnd: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const Configurations = () => {
  const [dataSource, setDataSource] = useState<Item[]>([
    {
      key: "0",
      round: 1,
      icon: 20,
      platinum: 20,
      gold: 20,
      silver: 20,
      bronze: 20,
    },
    {
      key: "1",
      round: 2,
      icon: 20,
      platinum: 20,
      gold: 20,
      silver: 20,
      bronze: 20,
    },
    {
      key: "2",
      round: 3,
      icon: 20,
      platinum: 20,
      gold: 20,
      silver: 20,
      bronze: 20,
    },
    {
      key: "3",
      round: 4,
      icon: 20,
      platinum: 20,
      gold: 20,
      silver: 20,
      bronze: 20,
    },
    {
      key: "4",
      round: 5,
      icon: 20,
      platinum: 20,
      gold: 20,
      silver: 20,
      bronze: 20,
    },
    {
      key: "5",
      round: 6,
      icon: 20,
      platinum: 20,
      gold: 20,
      silver: 20,
      bronze: 20,
    },
    {
      key: "6",
      round: 7,
      icon: 20,
      platinum: 20,
      gold: 20,
      silver: 20,
      bronze: 20,
    },
    {
      key: "7",
      round: 8,
      icon: 20,
      platinum: 20,
      gold: 20,
      silver: 20,
      bronze: 20,
    },
    {
      key: "8",
      round: 9,
      icon: 20,
      platinum: 20,
      gold: 20,
      silver: 20,
      bronze: 20,
    },
  ]);
  const [draftLimit, setDraftLimit] = useState<string | number | null>("5");
  const [updateFrequency, setUpdateFrequency] = useState<Dayjs | null>(null);

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      dataIndex: "round",
      title: "Round",
      width: "10%",
    },
    {
      title: <Tag color="#8b0000">Icon</Tag>,
      dataIndex: "icon",
      width: "16%",
      editable: true,
    },
    {
      title: <Tag color="#9400d3">Platinum</Tag>,
      dataIndex: "platinum",
      width: "16%",
      editable: true,
    },
    {
      title: <Tag color="#aa6c39">Gold</Tag>,
      dataIndex: "gold",
      width: "16%",
      editable: true,
    },
    {
      title: <Tag color="#c0c0c0">Silver</Tag>,
      dataIndex: "silver",
      width: "16%",
      editable: true,
    },
    {
      title: <Tag color="#5c3a0a">Bronze</Tag>,
      dataIndex: "bronze",
      width: "16%",
      editable: true,
    },
    {
      title: "Total",
      dataIndex: "total",
      width: "10%",
      render: (_, record: any) => {
        const totalSum =
          record.icon +
          record.platinum +
          record.gold +
          record.silver +
          record.bronze;
        const color = totalSum !== 100 ? "red" : "black";

        return <div style={{ color }}>{totalSum}</div>;
      },
    },
  ];

  const handleSave = (row: Item) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <Layout className={styles.tableLayout}>
      <Row style={{ height: 32, justifyContent: "space-between" }}>
        <Col span={7}>
          <Typography.Title style={{ margin: 0 }} level={5}>
            Configurations
          </Typography.Title>
        </Col>
        <Col>
          <Form.Item style={{ margin: 0 }}>
            <Button type="primary" onClick={() => {}}>
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Layout.Content
        className={styles.content}
        style={{ backgroundColor: "white" }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <Typography.Text>
              Odds of receiving a player card in each tier
            </Typography.Text>
            <Table
              components={components}
              rowClassName={() => "editable-row"}
              pagination={false}
              size="small"
              dataSource={dataSource}
              columns={columns as ColumnTypes}
            />
          </Space>
          <Space style={{ justifyContent: "space-between", width: "100%" }}>
            <Space>
              <Typography.Text>Daily draft limit</Typography.Text>
              <InputNumber
                value={draftLimit}
                onChange={(value) => setDraftLimit(value)}
              />
            </Space>
            <Space>
              <Typography.Text>
                Update players database script frequency
              </Typography.Text>
              <DatePicker
                picker="time"
                value={updateFrequency}
                onChange={setUpdateFrequency}
              />
            </Space>
          </Space>
        </Space>
      </Layout.Content>
    </Layout>
  );
};

export default Configurations;
