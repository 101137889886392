import { useEffect, useRef, useState } from "react";
import { Col, Layout, Row, Table, Typography } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import styles from "./Notifications.module.scss";
import ChangeNotificationModal from "./ChangeNotificationModal/ChangeNotificationModal";

const dataSource = [
  {
    id: "1",
    type: "Type 1",
    title: "Title 1",
    body: "Body 1",
    date: dayjs(),
    isRepeating: false,
  },
  {
    id: "2",
    type: "Type 2",
    title: "Title 2",
    body: "Body 2",
    date: dayjs(),
    isRepeating: true,
  },
];

const columns = [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
    width: 100,
  },
  {
    key: "type",
    dataIndex: "type",
    title: "Type",
  },
  {
    key: "title",
    dataIndex: "title",
    title: "Title",
  },
  {
    key: "body",
    dataIndex: "body",
    title: "Body",
  },
  {
    key: "date",
    dataIndex: "date",
    title: "Date of delivery",
    render: (date: Date) => dayjs(date).format("YYYY-MM-DD hh:mm:ss A"),
  },
  {
    key: "isRepeating",
    dataIndex: "isRepeating",
    title: "Repeating",
    width: 100,
    render: (isRepeating: boolean) =>
      isRepeating ? (
        <PlusOutlined style={{ fontSize: 18, color: "green" }} />
      ) : (
        <MinusOutlined style={{ fontSize: 18, color: "red" }} />
      ),
  },
];

interface INotification {
  id: string;
  type: string;
  title: string;
  body: string;
  date: any;
  isRepeating: boolean;
}

const Notifications = () => {
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [selectedNotification, setSelectedNotification] =
    useState<INotification | null>(null);

  const containerRef = useRef<any>();

  const onOpenNotification = (notification: INotification) => {
    setSelectedNotification(notification);
  };

  useEffect(() => {
    setTimeout(() => {
      if ("offsetHeight" in containerRef.current) {
        setContainerHeight(containerRef.current.offsetHeight - 140);
      }
    }, 100);
  }, []);

  return (
    <Layout className={styles.tableLayout}>
      <ChangeNotificationModal
        open={!!selectedNotification}
        notification={selectedNotification}
        onClose={() => setSelectedNotification(null)}
      />

      <Row style={{ height: 32 }}>
        <Col span={7}>
          <Typography.Title style={{ margin: 0 }} level={5}>
            Notifications (2)
          </Typography.Title>
        </Col>
      </Row>
      <Layout.Content
        ref={containerRef}
        className={styles.content}
        style={{ backgroundColor: "white" }}
      >
        <Table
          rowKey="key"
          dataSource={dataSource}
          onRow={(notification: INotification) => ({
            onClick: () => onOpenNotification(notification),
          })}
          // onChange={onTableChange}
          // rowClassName={styles.tableRow}
          columns={columns}
          // loading={questionsLoading || questionsFetching}
          // pagination={{
          //   current: notApprovedQuestionsPage + 1,
          //   total: data?.page?.totalElements || 0,
          //   pageSize,
          //   showSizeChanger: false,
          // }}
          size="small"
          scroll={{ y: containerHeight }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default Notifications;
